import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/networkingList"
  },
  {
    path: "/homeView",
    name: "homeView",
    meta: {
      title: '首页'
    },
    component: () => import('@/views/homeView.vue'),
    children: [
      {
        path: "/networkingList",
        name: "networkingList",
        meta: {
          title: '人脉列表页面'
        },
        component: () => import('@/views/networkingList.vue')
      },{
        path: "/advancedSearch",
        name: "advancedSearch",
        meta: {
          title: '高级搜索页面'
        },
        component: () => import('@/views/advancedSearch.vue')
      },{
        path: "/contrastView",
        name: "contrastView",
        meta: {
          title: '对比人脉页面'
        },
        component: () => import('@/views/contrastView.vue')
      },{
        path: "/myMessage",
        name: "myMessage",
        meta: {
          title: '我的消息页面'
        },
        component: () => import('@/views/myMessage.vue')
      },{
        path: "/personal",
        name: "personal",
        meta: {
          title: '个人信息'
        },
        component: () => import('@/views/personal/index.vue'),
        children:[
          {
            path: "/personalDetails",
            name: "personalDetails",
            meta: {
              title: '个人信息详情页面'
            },
            component: () => import('@/views/personal/personalDetails.vue')
          },{
            path: "/haveAlready",
            name: "haveAlready",
            meta: {
              title: '已收藏'
            },
            component: () => import('@/views/personal/haveAlready.vue')
          },{
            path: "/myAssessment",
            name: "myAssessment",
            meta: {
              title: '我对已获取联系方式会员的评价'
            },
            component: () => import('@/views/personal/myAssessment.vue')
          },{
            path: "/evaluativeSelf",
            name: "evaluativeSelf",
            meta: {
              title: '其他会员对我的评价'
            },
            component: () => import('@/views/personal/evaluativeSelf.vue')
          },{
            path: "/evaluateView",
            name: "evaluateView",
            meta: {
              title: '评价展示页面'
            },
            component: () => import('@/views/personal/evaluateView.vue')
          },{
            path: "/accountSecurity",
            name: "accountSecurity",
            meta: {
              title: '账号与安全'
            },
            component: () => import('@/views/personal/accountSecurity.vue')
          },{
            path: "/myWallet",
            name: "myWallet",
            meta: {
              title: '我的钱包'
            },
            component: () => import('@/views/personal/myWallet.vue')
          }
        ]
      },{
        path: "/aboutUs",
        name: "aboutUs",
        meta: {
          title: '关于我们'
        },
        component: () => import('@/views/aboutUs.vue')
      }
      
    ]
  },
];

const router = new VueRouter({
  routes,
});
// 设置路由守卫 to前往路由信息，from要离开的路由信息
// next参数是一个方法 有三种next()继续执行跳转，next('/login')跳转指定路由，next(false)停止跳转
router.beforeEach((to, from, next) => {
  if(to.fullPath !=from.fullPath){
    next();
  }
//   let toName = to.name
//   // 校验白名单 可以把白名单的name放这里进行判断
//   let notPermission = ['loginPage', 'homePage']
//   // 判断白名单中是否有这个name有的话就直接跳
//   if (notPermission.indexOf(toName) !== -1) {
//     next()
//   } else {
//     // 判断路由中token是否存在，存在就继续，不存在跳转到登录页。
//     //一定要判断要跳转的页面不能是next跳的页面 会造成死循环的
//     if (localStorage.getItem('token') || toName === 'login') {
//       next()
//     }else{
//       next('/login')
//     }
//   }
})
export default router