import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: '', // 登录token
    userInfo: null, // 用户信息
    loading: false, // 加载动画
    comparisonUpdate:{}, // 对比库更新
    comparisonShow: false, // 对比库显示
    // 行业类型
    industryList: [],
    // 会员角色
    occupationList: [],
    // 专业类型
    majorTypeList: [],
    searParameter: { // 获取首页列表参数
      occupationNum: null, //会员角色
      industry: null, // 会员行业
      gender: null, //性别
      workState: null, // 目前工作状态
      specialty: null, //所属专业
      jobGrade: null, // 职称
      certificationName: null, // 执业资格名称
      nowCity: null, //当前所在城市
    }
  },
  mutations: {
    // 对比库打开
    setComparisonShow(state, arr) {
      state.comparisonShow = arr;
    },
    // 修改对比库数据
    setComparisonUpdate(state, arr) {
      state.comparisonUpdate = arr;
    },
    // 修改加载动画
    setLoading(state, arr) {
      state.loading = arr;
    },
    // 修改专业类型
    setMajorTypeList(state, arr) {
      state.majorTypeList = arr;
    },
    // 修改首页列表参数
    setSearParameter(state, data) {
      if(data.singleKey){
        state.searParameter[data.singleKey] = data.data;
      }else{
        state.searParameter = data;
      }
    },
    // 储存token
    setToken(state, arr) {
      state.token = arr;
    },
    // 删除token
    removeToken(state, arr) {
      state.token = '';
    },
    // 储存用户信息
    setUserInfo(state, arr) {
      state.userInfo = arr;
    },
    // 删除用户信息
    removeUserInfo(state, arr) {
      state.userInfo = null;
    },
    // 储存行业会员信息
    setIndustryList(state, arr) {
      state.industryList = arr;
    },
    // 储存会员角色
    setOccupationList(state, arr) {
      state.occupationList = arr;
    },
  },
  actions: {
  },
  modules: {
  }
})
