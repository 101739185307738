import axios from "axios";
import store from "@/store";
import usage from '../utils/usage'
import { Message,Loading } from 'element-ui'

// 生产地址配置
let baseURL = "";
// if (process.env.NODE_ENV === "production") {
//   baseURL = "/zhcs-whp";
// } else {
//   baseURL = "/zhcs-whp";
// }

const instance = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 30,
  withCredentials: true,
  // headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  // }
});

// 不用加载动画的接口白名单
const loginWhite=['member/memberPay/query'];
// 匹配白名单
function containsSubstring(str) {
  for (let substring of loginWhite) {
    if (str.includes(substring)) {
      return true;
    }
  }
  return false;
}


// 请求拦截
instance.interceptors.request.use(
  (request) => {
    console.log(request)
    if(!containsSubstring(request.url)){
      store.commit("setLoading", true);
    }
    // request.headers.ip = '127.0.0.1'
    request.headers.token = usage.getToken();
    return request;
  },
  (err) => {
    // loadingA.close();
    store.commit("setLoading", false);
    return Promise.reject(err);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    store.commit("setLoading", false);
    let { data } = response;
    if (data.result == "000000" || data.code == 0) {
      return data;
    } else if (response.status == 401) {
      Message.error('请重新登录')
      usage.logOut()
    } else {
      Message.error(data.msg || data.desc || "系统错误");
      return false;
    }
  },
  (err) => {
    store.commit("setLoading", false);
    if(err.response.status == '500'){
      Message.error('网络打了个盹,请刷新页面')
    }else if(err.response.status == '401'){
      Message.error('请先登录')
      usage.logOut()
    }
    return Promise.reject(err);
  }
);

// get方法
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// post方法
const $post = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, headers)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { $get, $post };
