import router from '@/router'
import store from '@/store'
import CryptoJS from "crypto-js";
import moment from 'moment'
import { Message } from 'element-ui'
import portApi from '@/api/api'

const TokenKey = 'token';
const UserInfoKey = 'User-Info';
let usage={
  // aes 加密
  encryptionAES(plaintText){
    var plaintTextVal = plaintText;
    var key = CryptoJS.enc.Utf8.parse('2wijdhru34np'+ moment().format('MMDD'));
    var options = {
        iv: key,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
    };
    var encryptedData = CryptoJS.AES.encrypt(plaintTextVal, key, options);
    var encryptedBase64Str = encryptedData.toString();
    return encryptedBase64Str;
  },
  // aes 解密
  decryptAES(encryptedBase64Str){ 
    var vals = encryptedBase64Str;
    var key = CryptoJS.enc.Utf8.parse('2wijdhru34np'+ moment().format('MMDD'));
    var options = {
        iv: key,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
    };
    var decryptedData = CryptoJS.AES.decrypt(vals, key, options);
    var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData);
    return decryptedStr
  },
  // 刷新页面缓存数据赋值vuexS
  cacheAssignmentVuex(){
    if(sessionStorage.getItem(TokenKey)){
      store.commit('setToken', sessionStorage.getItem(TokenKey))
      if(sessionStorage.getItem(UserInfoKey)){
        store.commit('setUserInfo', JSON.parse(sessionStorage.getItem(UserInfoKey)))
      }else{ // 没有用户数据，接口获取会员详情
        usage.getUserInfoApi();
      }
    }
    usage.initDictionary();
  },
  // 获取用户信息api
  getUserInfoApi(){
    return new Promise((resolve, reject) => {
      if(usage.getToken()){
        portApi.getUserInfo().then((res)=>{
          usage.setUserInfo(res.data)
          resolve(res.data)
        })
      }
    })
  },
  // 判断是否有基础信息
  ifBasics(){
    // 注册成功，跳转个人信息页面填基础信息
    usage.getUserInfoApi().then(res=>{
      portApi.personPreview().then(res=>{
        if(!res.data || !res.data.memberBasics){
          router.push({name:"personalDetails", query:{type:'user'}});
        }
      })
    })
  },

  // 初始接口字典查询
  initDictionary(){
    // 行业类型信息
    // portApi.industryList().then((res) => {
    //   store.commit('setIndustryList',res.data)
    // })
    store.commit('setIndustryList',[
      { name: '建筑工程',isSelect:false, img: 'build', onImg: 'on-build' },
      { name: '市政工程',isSelect:false, img: 'municipal', onImg: 'on-municipal' },
      { name: '公路工程',isSelect:false, img: 'highway', onImg: 'on-highway' },
      { name: '铁路工程',isSelect:false, img: 'railway', onImg: 'on-railway' },
      { name: '水利水运',isSelect:false, img: 'water', onImg: 'on-water' },
      { name: '民航工程',isSelect:false, img: 'aviation', onImg: 'on-aviation' },
      { name: '农林工程',isSelect:false, img: 'forestry', onImg: 'on-forestry' },
      { name: '电力工程',isSelect:false, img: 'power', onImg: 'on-power' },
      { name: '化工石化',isSelect:false, img: 'chemical', onImg: 'on-chemical' },
      { name: '矿山工程',isSelect:false, img: 'mine', onImg: 'on-mine' },
      { name: '其他工程',isSelect:false, img: 'other', onImg: 'on-other' },
    ])
    // 专业类型信息
    store.commit('setMajorTypeList',[
      { isSelect:false,name: '岩石专业'},
      { isSelect:false,name: '测量专业'},
      { isSelect:false,name: '规划专业'},
      { isSelect:false,name: '建筑专业'},
      { isSelect:false,name: '给排水专业'},
      { isSelect:false,name: '暖通专业'},
      { isSelect:false,name: '燃气专业'},
      { isSelect:false,name: '道路专业'},
      { isSelect:false,name: '交通专业'},
      { isSelect:false,name: '桥梁专业'},
      { isSelect:false,name: '园林/景观专业'},
      { isSelect:false,name: '造价专业'},
      { isSelect:false,name: 'BIW专业'},
      { isSelect:false,name: '其他专业'}
    ])
    // 会员角色信息下拉
    portApi.occupationList().then((res) => {
      store.commit('setOccupationList',res.data)
    })
  },

  time(){ // 当前时间
    return new Date().getTime(); //1603009495724,精确到毫秒
  },
  // 获取token
  getToken () {
    return store.state.token
  },

  // 储存token
  setToken (token) {
    sessionStorage.setItem(TokenKey, token);
    store.commit('setToken',token)
  },

  // 删除token
  removeToken () {
    sessionStorage.removeItem(TokenKey);
    store.commit('removeToken')
  },

  // 获取用户信息
  getUserInfo () {
    return store.state.userInfo;
  },

  // 设置用户信息
  setUserInfo (userInfo) {
    sessionStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
    store.commit('setUserInfo',userInfo)
  },

  // 删除用户信息
  removeUserInfo () {
    sessionStorage.removeItem(UserInfoKey);
    store.commit('removeUserInfo')
  },

  // 获取某个缓存
  getCache(name){
    if(sessionStorage.getItem(name)){
      return JSON.parse(sessionStorage.getItem(name))
    }else{
      return ''
    }
  },

  // 设置某个缓存
  setCache(name,data){
    sessionStorage.setItem(name,JSON.stringify(data))
  },

  // 删除某个缓存
  removeCache(name){
    sessionStorage.removeItem(name);
  },

  // 生成随机字符串
  randomString (len) {
    len = len || 32
    const $chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'
    const maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
  },

  // 用户密码验证
  checkPassword (str) {
    // 1.密码必须由字母、数字、特殊符号组成，区分大小写
    // 2.特殊符号包含（. _ ~ ! @ # $ ^ & *）
    // 3.密码长度为8-20位
    // ^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$
    /* eslint-disable */
    let passwordReg = /^.*(?=.*[\@#\!*$!])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/
    /* eslint-enable */
    let noSpace = /^\S*$/
    if (!noSpace.test(str)) {
      return '不能包含空格'
    } else if (str.length > 20 || str.length < 8) {
      return '长度必须为8-20位'
    } else if (!passwordReg.test(str)) {
      return '!@#*$，小写字母，大写字母，数字，均必须有至少一个！'
    } else {
      return false
    }
  },

  // 退出登录方法
  logOut(name){
    usage.removeToken();
    usage.removeUserInfo();
    portApi.logout().then((res) => {
      router.push({ name: 'networkingList'})
    }).catch(err=>{
      router.push({ name: 'networkingList'})
    })
  },
  // 获取时间
  timeNow() {
    let vWeek, vWeek_s, year, month, day, hours, minutes, seconds;
    vWeek = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    let date = new Date();
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();
    hours = date.getHours();
    hours = hours > 9 ? hours : "0" + hours;
    minutes = date.getMinutes();
    minutes = minutes > 9 ? minutes : "0" + minutes;
    seconds = date.getSeconds();
    seconds = seconds > 9 ? seconds : "0" + seconds;
    vWeek_s = date.getDay();
    let time = year + "-" + month + "-" + day + " " + vWeek[vWeek_s] + " " + hours + ":" + minutes + ":" + seconds + "\t";
    return time
  },
  // 获取url路径参数值
  getRequst (strName) {
    let str = window.location.href;
    let intPos = str.indexOf("?");
    let strRight = str.substr(intPos + 1);
    let arrTmp = strRight.split("&");
    for (let i = 0; i < arrTmp.length; i++) {
      let arrTemp = arrTmp[i].split("=");
      if (arrTemp[0].toUpperCase() == strName.toUpperCase()) return arrTemp[1];
    }
    return "";
  },
  // 判断年龄
  getAge (startDateStr) {
    if(startDateStr){
      // 解析给定的日期字符串为 Date 对象，补充默认的天数（1号）
      const startDate = new Date(`${startDateStr}-01`);
      // 获取当前日期
      const today = new Date();
      // 获取年份差异
      let years = today.getFullYear() - startDate.getFullYear();
      // 如果当前日期还没到当年给定的月份，需要减去一年
      const currentMonth = today.getMonth();
      const startDateMonth = startDate.getMonth();
      if (currentMonth < startDateMonth) {
        years -= 1;
      }
      return years;
    }else{
      return 0
    }
  },
  // 根据字符串转换数组
  strTranArr(str){
    const arr = str.split('、');
    const filteredArr = arr.filter(item => item.trim() !== '');
    return filteredArr
  }
}
export default usage
